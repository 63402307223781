<template>
  <div class="dump-page animated fadeIn container">
    <h1>덤프 관리</h1>
    <div class="mt-4">
      <select class="float-left form-control" placeholder="회원번호" v-model="selectType" style="width:150px">
        <option>회원번호</option>
        <option>추천코드</option>
      </select>
      <input
        type="text"
        class="float-left form-control ml-2"
        v-show="selectType == '회원번호'"
        placeholder="회원번호"
        v-model="userId"
        style="width:150px"
      />
      <input
        type="text"
        class="float-left form-control ml-2"
        v-show="selectType == '추천코드'"
        placeholder="추천코드"
        v-model="recommendCode"
        style="width:150px"
      />
      <datetime
        class="float-left ml-2"
        type="date"
        v-model="startDate"
        :phrases="{ ok: '확인', cancel: '취소' }"
        placeholder="시작일"
      />
      <datetime
        class="float-left ml-2"
        type="date"
        v-model="endDate"
        :phrases="{ ok: '확인', cancel: '취소' }"
        placeholder="종료일"
      />
      <div class="float-left ml-2 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="messageCheck" v-model="hasMessage" />
        <label class="custom-control-label" for="messageCheck">메시지 포함</label>
      </div>
      <b-btn variant="primary" class="float-left ml-1" @click.prevent="create">요청</b-btn>
    </div>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="200px">요청일시</td>
          <td width="500px">요청 정보</td>
          <td width="120px">시작일</td>
          <td width="120px">종료일</td>
          <td>상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.createdAt | dateFormat }}
          </td>
          <td style="max-width:500px;">
            <span v-if="item.userId != null"> 회원번호: {{ item.userId }} </span>
            <span class="single-line" :title="item.recommendCode" v-else> 추천코드: {{ item.recommendCode }} </span>
          </td>
          <td>
            {{ item.startDate | dateShortFormat }}
          </td>
          <td>
            {{ item.endDate | dateShortFormat }}
          </td>
          <td class="text-center">
            <button
              class="btn btn-info"
              v-on:click="getFile(item.filePath)"
              v-show="isRecent(item.createdAt) && item.filePath != null"
              style="color: white"
            >
              다운로드
            </button>
            <span v-show="item.filePath == null" style="color: #63c2de">준비중</span>
            <span v-show="!isRecent(item.createdAt)" style="color: #9a9a9a">만료됨</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DumpService from '@/services/DumpService'
import { Datetime } from 'vue-datetime'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'Dumps',
  components: {
    Datetime,
  },
  data() {
    return {
      items: [],
      userId: '',
      recommendCode: '',
      startDate: '',
      endDate: '',
      hasMessage: false,
      page: 0,
      selectType: '회원번호',
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getList()
  },
  methods: {
    async getFile(filePath) {
      axios({
        url: '/api/file?fileKey=' + filePath,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filePath.split('/').pop())
        document.body.appendChild(link)
        link.click()
      })
    },
    async getList() {
      const response = await DumpService.list({
        page: this.currentPage,
      })
      if (response.status == 200) {
        this.items = response.data.list
      }
    },
    async create() {
      if (this.selectType == '회원번호' && this.userId.length == 0) {
        alert('회원번호를 입력해주세요')

        return
      } else if (this.selectType == '추천코드' && this.recommendCode.length == 0) {
        alert('추천코드를 입력해주세요')
        return
      }

      if (this.startDate.length == 0) {
        alert('시작일을 입력해주세요')
        return
      }
      if (this.endDate.length == 0) {
        alert('종료일을 입력해주세요')
        return
      }

      let params = {
        startDate: this.startDate.substring(0, 10),
        endDate: this.endDate.substring(0, 10),
        hasMessage: this.hasMessage,
      }

      if (this.selectType == '회원번호') {
        params.userId = this.userId
      } else {
        params.recommendCode = this.recommendCode
      }
      const response = await DumpService.create(params)

      if (response.status == 200) {
        alert('성공')
        this.userId = ''
        this.startDate = ''
        this.endDate = ''
        this.hasMessage = false
        this.getList()
      }
    },
    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-3, 'days')
      return date.isBefore(moment(String(createdAt)))
    },
  },
}
</script>
<style>
.vdatetime-input {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  border: 1px solid #e4e7ea;
  height: 100%;
}

.single-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
</style>
